import Button from '@ingka/button'
import { Drawer, Flex } from '@mantine/core'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'
import { UserRole } from 'lib/types/user.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useMissions } from 'state/slices/api'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { ReviewMissions } from 'components/features/CoachingSessions/CoachingSessionEditor/ReviewMissions'
import { MissionsOverview } from 'components/features/CoworkerMVP/CoachingSession/MissionsOverview/MissionsOverview.component'
import { PageHeader } from 'components/layout/PageHeader'
import { FilterMissionsButtons } from 'components/pages/shared/MissionsPage/MissionsActions/FilterMissionsButtons'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useAnalytics } from './MissionPreviewList.hooks'
import { MissionPreviewListProps } from './MissionPreviewList.types'

const MissionPreviewList: React.FC<MissionPreviewListProps> = ({
  disableFilter = false,
  availableStatuses,
}) => {
  const coworkerId = useCoworkerId()
  const [showCreateMission, setShowCreateMission] = useState(false)

  const { t } = useTranslation()

  const { data: missions = [], isLoading, isError } = useMissions(coworkerId)

  const [selected, setSelected] = useState<MissionStatus>(MissionStatus.Active)

  useAnalytics(disableFilter, selected, coworkerId)

  const filteredMissions = missions.filter((mission) => mission.status === selected)

  const pageType = useCurrentPageType()

  if (isError) return <UnexpectedErrorMessage />

  return (
    <>
      <Flex justify="space-between" my={spacing100}>
        <Flex justify="flex-start">
          <FilterMissionsButtons
            missionsStatus={availableStatuses}
            selected={selected}
            setSelected={setSelected}
          />
        </Flex>
        {pageType === UserRole.TeamLead && (
          <Button type="primary" onClick={() => setShowCreateMission(true)}>
            {t('features.mission.actions.manage-missions')}
          </Button>
        )}
      </Flex>
      <MissionsOverview
        missions={filteredMissions}
        isLoading={isLoading}
        completed={selected === MissionStatus.Completed}
      />
      <ReviewMissionDrawer isOpen={showCreateMission} close={() => setShowCreateMission(false)} />
    </>
  )
}

interface Props {
  isOpen: boolean
  close: () => void
}

const ReviewMissionDrawer: FC<Props> = ({ isOpen, close }) => {
  const coworkerId = useCoworkerId()
  const { t } = useTranslation()
  return (
    <Drawer
      title={<PageHeader title={t('features.mission.create-mission.edit-mission')} />}
      opened={isOpen}
      onClose={close}
      withCloseButton={true}
      withinPortal={true}
      size="100%"
      transitionProps={{
        transition: 'slide-up',
        duration: 600,
        timingFunction: 'ease',
      }}
      zIndex={1000}
      position="bottom"
    >
      <ReviewMissions coworkerId={coworkerId} />
    </Drawer>
  )
}

export default MissionPreviewList
