import Button from '@ingka/button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { Widget, WidgetFooter, WidgetHeader } from 'components/composites/Shared/Widget'
import { DatePicker } from 'components/primitives/DatePicker'

import { useHandleCreateCoachingSessionClicked } from './NoPlannedCoachingSessionPrompt.hooks'
import { NoPlannedCoachingSessionPromptProps } from './NoPlannedCoachingSessionPrompt.types'

const NoPlannedCoachingSessionPrompt: React.FC<NoPlannedCoachingSessionPromptProps> = ({
  coworkerId,
}) => {
  const { t } = useTranslation()

  const { teamleadId } = useParams()

  const [createCoachingSession, { isLoading: isLoadingCreateCoachingSession }] =
    useHandleCreateCoachingSessionClicked()

  const [plannedDate, setPlannedDate] = useState<Date>(new Date())

  return (
    <Widget
      header={<WidgetHeader title={t('features.coaching-session.no-coaching-session-planned')} />}
      footer={
        <WidgetFooter>
          <DatePicker
            id="1"
            label={t('features.coaching-session.actions.choose-date')}
            value={plannedDate}
            onChange={(v) => {
              setPlannedDate(v)
            }}
            minDate={new Date()}
          ></DatePicker>
          <Button
            small
            type="primary"
            text={t('features.coaching-session.actions.plan-coaching-session')}
            onClick={() => {
              analyticsHelper.createCustomElementClickEvent(
                {
                  key: CustomElementClickEventKeys.PlanCoachingSession,
                  description:
                    'Custom event when teamlead clicks on plan for the first coaching session.',
                },
                {
                  coworkerId: coworkerId,
                  teamleadId: teamleadId,
                  chosenDate: plannedDate,
                }
              )
              createCoachingSession(coworkerId, plannedDate)
            }}
            loading={isLoadingCreateCoachingSession}
            style={{ alignSelf: 'end' }}
          />
        </WidgetFooter>
      }
    />
  )
}

export default NoPlannedCoachingSessionPrompt
