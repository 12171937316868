import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import Text from '@ingka/text'

interface Props {
  showModal: boolean
  handleClose: () => void
}

const EfficiencyInfoModal = ({ showModal, handleClose }: Props) => {
  return (
    <Modal visible={showModal} handleCloseBtn={handleClose}>
      <Sheets header={<ModalHeader></ModalHeader>} footer={<ModalFooter>{''}</ModalFooter>}>
        <ModalBody>
          <Text headingSize="m">{'Performance numbers'}</Text>
          <Text bodySize="m">
            {
              'Gain deeper insights into your performance over the last 30 days. The data is based on your interactions with customers and how you have handled them.'
            }
          </Text>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default EfficiencyInfoModal
