import InlineMessage from '@ingka/inline-message'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions'

import { useCoworker } from 'state/slices/api'

import { SessionCommentEditor } from 'components/features/CoachingSessions/CoachingSessionEditor/SessionCommentEditor'
import MissionDraftSkeleton from 'components/features/Missions/MissionDraftEditor/MissionDraftSkeleton/MissionDraftSkeleton.component'

import { MissionDiffs, MissionDiffsSummary } from './MissionDiffs'

type SummaryCoachingSessionProps = {
  coworkerId: string
  coachingSession: CoachingSession
}

const Summary: React.FC<SummaryCoachingSessionProps> = ({ coworkerId, coachingSession }) => {
  const {
    data: sessionMissions,
    isLoading: loadingMissions,
    isError: errorMissions,
    isSuccess: successMissions,
  } = useMissionsForCoachingSession({
    coworkerId,
    coachingSessionId: coachingSession.id,
  })

  const { data: coworker } = useCoworker(coworkerId)

  return (
    <>
      {errorMissions && <InlineMessage variant="cautionary" />}

      {/* Mission diffs summary/new state */}
      <MissionDiffsSummary coworkerId={coworkerId} missions={sessionMissions} />

      {/* Session comment with the RTF editor */}
      {coworker && (
        <>
          <SessionCommentEditor coworker={coworker} coachingSession={coachingSession} />
        </>
      )}

      {/* Mission recap in Summary and Commit */}
      {loadingMissions && <MissionDraftSkeleton />}
      {successMissions && (
        <>
          <MissionDiffs missions={sessionMissions} coworkerId={coworkerId} />
        </>
      )}
    </>
  )
}

export default Summary
