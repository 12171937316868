import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'

import { spacing25 } from 'styles/tokens/insikt.tokens'

import { SubHeading } from 'components/primitives/Text'

import { MissionProgress } from '../../MissionProgress/MissionProgress.component'
import { MissionProgressArrow } from '../../MissionProgressArrow/MissionProgressArrow.component'
import { missionCardBody, transKey } from '../MissionPreview.constants'

type Props = {
  initialScore: number
  currentScore: number
  mission: Mission
}

export const MissionBody = ({ initialScore, currentScore, mission }: Props) => {
  const { t } = useTranslation()
  return (
    <Flex direction="column" style={missionCardBody}>
      <SubHeading style={{ margin: spacing25 }}>{t(`${transKey}.objective`)}</SubHeading>

      <MissionProgressArrow
        initialScore={initialScore}
        target={mission.target ?? mission.changes.target}
      />
      <SubHeading headingSize="xs" style={{ margin: spacing25 }}>
        {t(`${transKey}.status`)}
      </SubHeading>
      <MissionProgress
        currentScore={currentScore}
        isCompleted={mission.status === MissionStatus.Completed}
        targetScore={mission.target}
        startScore={initialScore}
      />
    </Flex>
  )
}
