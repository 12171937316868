import FormattedCoworkerNote from 'components/features/CoworkerMVP/CoworkerCreateNote/FormattedCoworkerNote.component'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import ReviewEvaluatedInteractions from './ReviewEvaluatedInteractions/ReviewEvaluatedInteractions.component'
import { ReviewMetricsProps } from './ReviewMetrics.types'

const ReviewMetrics: React.FC<ReviewMetricsProps> = ({ coworkerId, coachingSession }) => {
  return (
    <>
      <ReviewEvaluatedInteractions coworkerId={coworkerId} />
      <FeatureToggle featureKey="coworkerNew">
        <FormattedCoworkerNote coachingSession={coachingSession} />
      </FeatureToggle>
    </>
  )
}

export default ReviewMetrics
