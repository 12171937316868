import { useCallback } from 'react'

import { DateHelper } from 'lib/utils/date.helper'

import { useUser } from 'hooks/users'

import { useCreateCoachingSession } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

export const useHandleCreateCoachingSessionClicked = () => {
  const [createCoachingSession, meta] = useCreateCoachingSession()
  const { data: user } = useUser()

  const handler = useCallback(
    (coworkerId: string, plannedDate: Date) => {
      if (!user) return

      const createCoachingSessionArgs = {
        coworkerId,
        createdBy: user.legacyId,
        createdDate: DateHelper.dateToString(new Date()),
        plannedDate: DateHelper.dateToString(plannedDate),
      }

      createCoachingSession(createCoachingSessionArgs)
    },
    [createCoachingSession, user]
  )

  return [handler, meta] as const
}
