import { useEditor } from '@tiptap/react'

import { commonExtensions } from '../constants'

export const useViewOnlyEditor = (content: string) => {
  return useEditor({
    editable: false,
    extensions: [...commonExtensions],
    content
  })
}
