import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import clipboardPencil from '@ingka/ssr-icon/paths/clipboard-pencil'
import Text from '@ingka/text'
import { Accordion, Box, Flex, Grid, Group, Skeleton, Stack } from '@mantine/core'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'
import { UserRole } from 'lib/types/user.types'
import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import {
  useDeleteMissionDraftClicked,
  useSuggestCompleteMissionClicked,
} from 'hooks/missions/useMissionActions.hooks'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'
import { useUser } from 'hooks/users'

import { radius25, spacing50, spacing75, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { MissionCoachingPointersFormatted } from 'components/composites/Missions/MissionCoachingPointersFormatted'
import { MissionPreview } from 'components/composites/Missions/MissionPreview'
import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { GridVariant } from 'components/composites/Shared/GridLayout/GridLayout.types'
import { PromptModal } from 'components/composites/Shared/PromptModal'
import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'

import { useMissionMonthSplit } from './MissionsOverview.hooks'

type MissionOverviewProps = {
  missions: Mission[]
  header?: boolean
  isLoading: boolean
  completed?: boolean
}

// TODO: Maybe move this header? Do we want it in any other place than Upcomming coaching session?
// TODO: We could simplify this with nice returns if not for this header - definetly think about something there
export const MissionsOverview: React.FC<MissionOverviewProps> = ({
  missions,
  isLoading,
  completed = false,
}) => {
  if (isLoading) {
    return <MissionWithPointersSkeleton />
  }

  if (missions.length === 0) {
    // Select the most relevant translation to show the user
    const missingMissionsKey = completed
      ? 'features.missisAdmin()ion.warnings.no-completed'
      : 'features.mission.warnings.no-data'
    return <EmptyContent translationKey={missingMissionsKey} />
  }

  if (completed) {
    return <CompletedMissionsWithMonthSplit missions={missions} />
  }
  return <MissionWithPointers missions={missions} />
}

const CompletedMissionsWithMonthSplit = ({ missions }: { missions: Mission[] }) => {
  const splitMonths = useMissionMonthSplit(missions)
  const { t } = useTranslation()
  return (
    <>
      {splitMonths.map((month, index) => (
        <Accordion
          key={index}
          defaultValue="0"
          className="accordion-split-missions"
          mb={spacing100}
        >
          <Accordion.Item value={'' + index}>
            <Accordion.Control
              style={{
                backgroundColor: 'white',
                borderRadius: radius25,
                fontSize: '1.2rem',
              }}
            >
              <b>{`${t(`common.months.${month.month}`)} - ${month.year}`}</b>
            </Accordion.Control>
            <Accordion.Panel mt={spacing100}>
              <MissionWithPointers missions={month.missions} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  )
}

const MissionWithPointers = ({ missions }: { missions: Mission[] }) => {
  const coworkerId = useCoworkerId()

  return (
    <>
      {missions.map((mission, index) => (
        <Fragment key={index}>
          {missions.length > 1 && <SubHeading>{'Mission ' + (index + 1)}</SubHeading>}
          <GridLayout
            padding={0}
            gridStyles={{ marginTop: '0' }}
            key={mission.id}
            children={[
              {
                element: <MissionPreview mission={mission} coworkerId={coworkerId} />,
                variant: GridVariant.OneSidedSmall,
              },
              {
                element: (
                  <CoachingPointersOverview
                    coachingPointers={mission.coachingPointers ?? mission.changes.coachingPointers}
                  />
                ),
                variant: GridVariant.OneSidedLarge,
              },
              {
                element: <EditButtonsWidget mission={mission} />,
              },
            ]}
          />
        </Fragment>
      ))}
    </>
  )
}

type EditProps = {
  mission: Mission
}

const EditButtonsWidget: FC<EditProps> = ({ mission }) => {
  const coworkerId = useCoworkerId()
  const { data: user } = useUser()
  const [showSuggestCompleteModal, setShowSuggestCompleteModal] = useState(false)
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false)
  const pageType = useCurrentPageType()
  const { t } = useTranslation()

  const [handleDeleteMissionDraft, { isLoading: isLoadingDeleteDraft }] =
    useDeleteMissionDraftClicked(coworkerId, mission.id)

  const [suggestMission, suggestMissionArgs, { isLoading: isLoadingSuggestComplete }] =
    useSuggestCompleteMissionClicked(coworkerId, mission.id, mission.suggestedToComplete)

  // Confirm suggest to complete or undo suggested to complete
  const handleSuggestedCompleteMissionClicked = async () => {
    await suggestMission(suggestMissionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.SuggestCompleteMission,
        description:
          'Custom event for tracking when a teamlead clicks on the suggest to complete mission button.',
      },

      {
        ...suggestMissionArgs,
        createdBy: user?.legacyId,
      }
    )
    setShowSuggestCompleteModal(false)
  }

  const undoText = mission.suggestedToComplete ? 'undo-' : ''

  if (pageType !== UserRole.TeamLead) {
    return null
  }
  return (
    <>
      <Widget>
        <Flex justify="flex-end">
          {mission.status === MissionStatus.Active && (
            <Button small type="primary" onClick={() => setShowSuggestCompleteModal(true)}>
              {t(`features.mission.actions.${undoText}suggest-to-complete`)}
            </Button>
          )}
          {mission.status === MissionStatus.Draft && (
            <Button small type="primary" onClick={() => setShowDeleteDraftModal(true)}>
              {t('features.mission.actions.delete-draft')}
            </Button>
          )}
        </Flex>
      </Widget>
      {showSuggestCompleteModal && (
        <PromptModal
          title={t(
            `features.mission.confirm-mission-actions-modal.${undoText}suggest-to-complete.title`
          )}
          titleId={t(`${undoText}suggest-to-complete-mission-modal`)}
          content={t(
            `features.mission.confirm-mission-actions-modal.${undoText}suggest-to-complete.warnings`
          )}
          onCancel={() => setShowSuggestCompleteModal(false)}
          onConfirm={() => handleSuggestedCompleteMissionClicked()}
          loading={isLoadingSuggestComplete}
        />
      )}
      <DeleteDraftHandler
        visible={showDeleteDraftModal}
        setShowVisible={setShowDeleteDraftModal}
        handleAction={handleDeleteMissionDraft}
        isLoadingDeleteDraft={isLoadingDeleteDraft}
      />
    </>
  )
}

type DeleteDraftHandlerProps = {
  visible: boolean
  setShowVisible: (visible: boolean) => void
  isLoadingDeleteDraft: boolean
  handleAction: () => void
}

const DeleteDraftHandler: FC<DeleteDraftHandlerProps> = ({
  visible,
  setShowVisible,
  isLoadingDeleteDraft,
  handleAction,
}) => {
  const { t } = useTranslation()
  if (!visible) {
    return null
  }
  return (
    <PromptModal
      title={t('features.mission.confirm-mission-actions-modal.delete-draft.title')}
      titleId={t('delete-draft-mission-modal')}
      content={t('features.mission.confirm-mission-actions-modal.delete-draft.warnings')}
      onCancel={() => setShowVisible(false)}
      onConfirm={() => handleAction()}
      loading={isLoadingDeleteDraft}
    />
  )
}

const MissionWithPointersSkeleton = () => (
  <Grid>
    <GridLayout
      padding={0}
      children={[
        {
          element: <Skeleton height={'45vh'} />,
          variant: GridVariant.OneSidedSmall,
        },
        {
          element: <Skeleton height={'45vh'} />,
          variant: GridVariant.OneSidedLarge,
        },
      ]}
    />
  </Grid>
)

type CoachingPointersOverviewProps = {
  coachingPointers: string
}

const CoachingPointersOverview = ({ coachingPointers }: CoachingPointersOverviewProps) => {
  return (
    <Widget border style={{ height: '100%' }}>
      <Stack gap={spacing50} style={{ height: '100%' }}>
        <Header />
        <Box
          style={{
            backgroundColor: colourNeutralGrey100,
            padding: spacing75,
            borderRadius: radius25,
            height: '100%',
          }}
        >
          <MissionCoachingPointersFormatted coachingPointers={coachingPointers} />
        </Box>
      </Stack>
    </Widget>
  )
}

const Header = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Group mb={spacing50} gap={spacing50}>
        <SSRIcon paths={clipboardPencil} />
        <SubHeading>{t('features.coaching-session.coworker.coaching-pointers.title')}</SubHeading>
      </Group>
      <Text>{t('features.coaching-session.coworker.coaching-pointers.subtitle')}</Text>
    </Box>
  )
}
