import Button from '@ingka/button'
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal'
import { space100 } from '@ingka/variables'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useUpdateCoachingSession } from 'state/slices/api/modules/coachingSession'

import { DatePicker } from 'components/primitives/DatePicker'

import { CoachingSessionModalProps } from './CoachingSessionPreviewList.types'

const CoachingSessionModal: React.FC<CoachingSessionModalProps> = ({
  closeModal,
  visible,
  coachingSession,
}) => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()
  const [updateCoachingSession] = useUpdateCoachingSession()

  const [plannedDate, setPlannedDate] = useState<Date>(new Date())

  const handleClickUpdate = async () => {
    const updateCoachingSessionArgs = {
      coworkerId: coworkerId,
      coachingSessionId: coachingSession.id,
      plannedDate: DateHelper.dateToString(plannedDate),
    }

    await updateCoachingSession(updateCoachingSessionArgs)
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.EditCoaching,
        description:
          'Custom event for tracking when a teamlead edits date/assessments needed for a coaching session.',
      },
      updateCoachingSessionArgs
    )
    closeModal()
  }

  return (
    <Modal handleCloseBtn={closeModal} visible={visible}>
      <Sheets
        alignment="right"
        footer={
          <ModalFooter>
            <Button
              small
              disabled={
                DateHelper.dateToString(plannedDate) === coachingSession.plannedDate.split('T')[0]
              }
              type="primary"
              text={t('features.coaching-session.actions.update-coaching-session')}
              onClick={handleClickUpdate}
              style={{ marginTop: space100 }}
            />
          </ModalFooter>
        }
        header={
          <ModalHeader
            ariaCloseTxt="Close button text"
            backBtnClick={closeModal}
            title={t('features.coaching-session.edit-coaching-session.title')}
          />
        }
      >
        <ModalBody>
          <DatePicker
            id="1"
            label={t('features.coaching-session.actions.choose-planned-date')}
            value={plannedDate}
            onChange={(v) => {
              setPlannedDate(v)
            }}
            minDate={new Date()}
          />
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default CoachingSessionModal
