import { getTaskListExtension, Link as TipTapLink } from '@mantine/tiptap'
import TaskItem from '@tiptap/extension-task-item'
import TipTapTaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

import { LinkNode } from 'lib/types/richTextDocument.types'

export const commonExtensions = [
  StarterKit,
  getTaskListExtension(TipTapTaskList),
  TaskItem.configure({
    nested: true,
    HTMLAttributes: {
      class: 'coaching-pointers-task-items',
    },
  }),
  TipTapLink.configure({
    openOnClick: true,
    linkOnPaste: true,
    autolink: true,
    validate(url) {
      return /^(https?:\/\/)?(www\.)?/.test(url)
    },
    HTMLAttributes: {
      inclusive: false,
      href: (node: LinkNode) => node.attrs.href,
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
      class: 'editor-link',
    },
  }),
  Underline,
]
