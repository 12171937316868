import InlineMessage from '@ingka/inline-message'
import SSRIcon from '@ingka/ssr-icon'
import arrowOnPath from '@ingka/ssr-icon/paths/arrow-on-path'
import checkmark from '@ingka/ssr-icon/paths/checkmark'
import cleanSparkles from '@ingka/ssr-icon/paths/clean-sparkles'
import { space100, space150 } from '@ingka/variables'
import { Accordion, Box, Flex, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'
import { useFormatDate } from 'hooks/formatting/useFormatDate.hooks'

import { useCoachingSession, useMissions } from 'state/slices/api'

import { missionIconSquare } from 'styles/missions'

import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { CoachingSessionOverviewSkeleton } from 'components/features/CoachingSessions/CoachingSessionOverview/CoachingSessionOverviewSkeleton'
import { GreyBoldedSubheading, SubHeading } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { MissionRecap } from '../CoachingSessionEditor/Summary/MissionRecap'
import { CoachingSessionOverviewProps } from './CoachingSessionOverview.types'
import { SessionComment } from './SessionComment'

const CoachingSessionOverview: React.FC<CoachingSessionOverviewProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  const {
    data: missions,
    completedMissionIds,
    newMissionIds,
    isLoading: isLoadingMissions,
    isError: isErrorMissions,
  } = useMissionsForCoachingSession({ coworkerId, coachingSessionId })

  const formattedCompletedDate =
    coachingSession?.completedDate && formatDate(new Date(coachingSession.completedDate))

  // All active missions regardless of the coaching session cycle
  const allMissions: Mission[] = useMissions(coworkerId).data ?? []
  const allActiveMissions = allMissions.filter((mission) => mission.status === MissionStatus.Active)

  if (isLoadingCoachingSession || isLoadingMissions) {
    return <CoachingSessionOverviewSkeleton />
  }

  if (isErrorCoachingSession || isErrorMissions) {
    return <UnexpectedErrorMessage />
  }

  if (!coachingSession) {
    return <InlineMessage title={t('errors.not-found')} />
  }

  return (
    <Stack gap={space150} mt={space100}>
      <Widget
        header={
          <WidgetHeader
            title={
              <SubHeading>
                {t('features.coaching-session.session-completed-on', {
                  formattedDate: formattedCompletedDate,
                })}
              </SubHeading>
            }
          />
        }
      ></Widget>
      <Stack>
        <SessionComment comment={coachingSession.sessionComment ?? ''} />

        {!missions ||
          missions.length === 0 ||
          ((allActiveMissions.length === 0 || !allActiveMissions) && (
            <InlineMessage variant="cautionary" title={t('features.mission.warnings.no-data')} />
          ))}

        {(missions.length > 0 || allActiveMissions.length > 0) && (
          <Widget>
            <SubHeading>{t('features.coaching-session.overview.related-missions')}</SubHeading>
            <Accordion
              key={'related missions'}
              multiple
              defaultValue={['Completed missions', 'New missions', 'All active missions']}
            >
              <Accordion.Item id="test" key={'completed'} value={'Completed missions'}>
                <Accordion.Control disabled={completedMissionIds.length === 0}>
                  <Flex direction={'row'} align={'center'} gap={'sm'}>
                    <SSRIcon style={missionIconSquare} paths={checkmark} />
                    <GreyBoldedSubheading>
                      {t('features.coaching-session.overview.completed-missions')}
                    </GreyBoldedSubheading>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  {missions.map((mission) => {
                    if (!completedMissionIds.includes(mission.id)) return null
                    return (
                      <Box my={space100} key={mission.id}>
                        <MissionRecap
                          mission={mission}
                          coworkerId={coworkerId}
                          visualiseChanges={false}
                          showStatus={false}
                        />
                      </Box>
                    )
                  })}
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={'New missions'}>
                <Accordion.Control disabled={newMissionIds.length === 0}>
                  <Flex direction={'row'} align={'center'} gap={'sm'}>
                    <SSRIcon style={missionIconSquare} paths={cleanSparkles} />
                    <GreyBoldedSubheading>
                      {t('features.coaching-session.overview.new-missions')}
                    </GreyBoldedSubheading>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  {missions.map((mission) => {
                    if (!newMissionIds.includes(mission.id)) return null
                    return (
                      <Box my={space100} key={mission.id}>
                        <MissionRecap
                          mission={mission}
                          coworkerId={coworkerId}
                          visualiseChanges={false}
                          showStatus={false}
                        />
                      </Box>
                    )
                  })}
                </Accordion.Panel>
              </Accordion.Item>

              {allActiveMissions.length > 0 && (
                <Accordion.Item
                  id="all-active-missions"
                  key={'All active'}
                  value={'All active missions'}
                >
                  <Accordion.Control>
                    <Flex direction={'row'} align={'center'} gap={'sm'}>
                      <SSRIcon style={missionIconSquare} paths={arrowOnPath} />
                      <GreyBoldedSubheading>
                        {t('features.coaching-session.overview.all-active-missions')}
                      </GreyBoldedSubheading>
                    </Flex>
                  </Accordion.Control>
                  <Accordion.Panel>
                    {allActiveMissions.map((mission) => {
                      return (
                        <Box my={space100} key={mission.id}>
                          <MissionRecap
                            mission={mission}
                            coworkerId={coworkerId}
                            visualiseChanges={false}
                            showStatus={false}
                          />
                        </Box>
                      )
                    })}
                  </Accordion.Panel>
                </Accordion.Item>
              )}
            </Accordion>
          </Widget>
        )}
      </Stack>
    </Stack>
  )
}

export default CoachingSessionOverview
