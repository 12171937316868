import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import ArrowLeft from '@ingka/ssr-icon/paths/arrow-left'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { space100, space150 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys, CustomViewKeys } from 'lib/utils/analytics/analytics.types'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoachingSession, useCoworker } from 'state/slices/api'

import { ReviewMissions } from 'components/features/CoachingSessions/CoachingSessionEditor/ReviewMissions'
import { MissionDiffs } from 'components/features/CoachingSessions/CoachingSessionEditor/Summary/MissionDiffs'
import { PageHeader } from 'components/layout/PageHeader'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { StickyFooter } from 'components/layout/StickyFooter'
import { Heading, SubHeading } from 'components/primitives/Text'

import { useBackHref } from './PrepareCoachingSessionPage.hooks'

const PrepareCoachingSessionPage: React.FC = () => {
  const { t } = useTranslation()
  const backHref = useBackHref()
  const [openModal, setOpenModal] = useState(false)
  const { coworkerId, coachingSessionId } = useParams()

  const coworker = useCoworker(coworkerId ?? skipToken).data

  const coachingSession = useCoachingSession({
    coachingSessionId: coachingSessionId!,
    coworkerId: coworkerId!,
  }).data
  const locationState = useLocation().state

  const [review, setReview] = useState(locationState.review ?? false)

  const { pathname, hash } = useLocation()
  // Used for navigation back to teamlead tabs
  const fromUrl = pathname + hash
  const nav = useNavigate()

  const { data: sessionMissions } = useMissionsForCoachingSession({
    coworkerId: coworkerId!,
    coachingSessionId: coachingSessionId!,
  })

  useEffect(() => {
    if (review) {
      analyticsHelper.createCustomViewEvent({
        key: CustomViewKeys.PrepReview,
        description: 'Reviewing the preparation',
        coworkerId: coworkerId,
      })
    } else {
      analyticsHelper.createCustomViewEvent({
        key: CustomViewKeys.PrepPrepare,
        description: 'Preparing for the coaching session',
        coworkerId: coworkerId,
      })
    }
  }, [review, coworkerId])

  useValidateParams(['coworkerId', 'coachingSessionId'])
  if (!coworkerId || !coachingSessionId) return <RedirectingLayout />

  return (
    <>
      <PageHeader
        title={t('features.coaching-session.prepare-page.back-to-coworker-with-name', {
          coworkerName: coworker?.fullName,
        })}
        backHref={backHref}
      />
      {coachingSession && (
        <Box mt={space100}>
          {review ? (
            <>
              <Heading>{t('features.coaching-session.prepare-page.summary-title')}</Heading>
              <SubHeading style={{ marginBottom: space150 }}>
                {t('features.coaching-session.prepare-page.summary-subtitle')}
              </SubHeading>
              <MissionDiffs missions={sessionMissions} coworkerId={coworkerId} />

              <StickyFooter>
                <Flex
                  direction={'row'}
                  align={'center'}
                  justify={'center'}
                  gap={24}
                  mt={10}
                  w={'100%'}
                >
                  <Button
                    type="emphasised"
                    small
                    text={t('features.coaching-session.prepare-page.modify-preparation')}
                    ssrIcon={ArrowLeft}
                    onClick={async () => {
                      analyticsHelper.createCustomElementClickEvent(
                        {
                          key: CustomElementClickEventKeys.PrepareCoaching,
                          description:
                            'Custom event for tracking when a teamlead clicks on the modify preparation button for the first time after clicking review.',
                        },
                        {
                          coachingSessionId: coachingSessionId,
                          coworkerId: coworkerId,
                        }
                      )

                      locationState.review = false
                      setReview(false)
                    }}
                    style={{ width: 200 }}
                  />

                  {locationState.review ? (
                    <Button
                      type="primary"
                      small
                      text={t('features.coaching-session.prepare-page.done')}
                      ssrIcon={arrowRight}
                      iconPosition="trailing"
                      onClick={() => nav(backHref, { state: { from: fromUrl } })}
                      style={{ width: 200 }}
                    />
                  ) : (
                    <Button
                      type="primary"
                      small
                      text={t('features.coaching-session.prepare-page.finish-preparation')}
                      ssrIcon={arrowRight}
                      iconPosition="trailing"
                      onClick={() => setOpenModal(true)}
                      style={{ width: 200 }}
                    />
                  )}
                </Flex>
              </StickyFooter>
              <Modal visible={openModal} handleCloseBtn={() => setOpenModal(false)}>
                <Prompt
                  title={t('features.coaching-session.prepare-page.ready-for-coaching')}
                  titleId="complete-coaching-session-modal"
                  header={<ModalHeader />}
                  footer={
                    <ModalFooter>
                      <Button
                        small
                        type="secondary"
                        text={t('features.coaching-session.prepare-page.no')}
                        style={{ width: 100 }}
                        onClick={async () => {
                          analyticsHelper.createCustomElementClickEvent(
                            {
                              key: CustomElementClickEventKeys.ReadyForCoaching,
                              description:
                                'Custom event for tracking when a teamlead clicks finish review.',
                            },
                            {
                              coaching_id: coachingSessionId,
                              answer: 'no',
                            }
                          )
                          nav(backHref, { state: { from: fromUrl } })
                        }}
                      />
                      <Button
                        small
                        type="primary"
                        text={t('features.coaching-session.prepare-page.yes')}
                        style={{ width: 100 }}
                        onClick={async () => {
                          analyticsHelper.createCustomElementClickEvent(
                            {
                              key: CustomElementClickEventKeys.ReadyForCoaching,
                              description:
                                'Custom event for tracking when a teamlead clicks finish review.',
                            },
                            {
                              coaching_id: coachingSessionId,
                              answer: 'yes',
                            }
                          )
                          nav(backHref, { state: { from: fromUrl } })
                        }}
                      />
                    </ModalFooter>
                  }
                ></Prompt>
              </Modal>
            </>
          ) : (
            <>
              <Heading style={{ marginBottom: space100 }}>
                {t('features.coaching-session.prepare-page.prepare-title')}
              </Heading>
              <SubHeading style={{ marginBottom: space150 }}>
                {t('features.coaching-session.prepare-page.prepare-subtitle')}
              </SubHeading>
              <ReviewMissions coworkerId={coworkerId} />

              <StickyFooter>
                <Flex direction={'row'} justify={'center'} gap={24} mt={10} w={'100%'}>
                  <Button
                    type="emphasised"
                    small
                    text={t('features.coaching-session.prepare-page.back-to-coworker')}
                    ssrIcon={ArrowLeft}
                    onClick={() => nav(backHref, { state: { from: fromUrl } })}
                    style={{ width: 200 }}
                  />
                  <Button
                    type="primary"
                    small
                    text={t('features.coaching-session.prepare-page.review-preparation')}
                    ssrIcon={arrowRight}
                    iconPosition="trailing"
                    onClick={() => setReview(true)}
                    style={{ width: 200 }}
                  />
                </Flex>
              </StickyFooter>
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default PrepareCoachingSessionPage
