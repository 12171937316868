import { Box } from '@mantine/core'
import { useState } from 'react'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { spacing200 } from 'styles/tokens/insikt.tokens'

import { LazyLoader } from 'components/composites/Shared/LazyLoader'
import ToggleButtons, {
  ButtonDefinition,
} from 'components/composites/Shared/ToggleButtons/ToggleButtons.component'
import { ReviewEvaluatedInteractions } from 'components/features/CoachingSessions/CoachingSessionEditor/ReviewMetrics/ReviewEvaluatedInteractions'
import { EmbedReport } from 'components/features/EmbedReport'

const enum FilterOptions {
  DiscoverConversations = 'discover_conversations',
  CurrentEvaluations = 'current_evaluations',
}

const buttons: ButtonDefinition[] = [
  {
    key: FilterOptions.CurrentEvaluations,
    translationKey: 'pages.manage-coworker.tabs.explore.current-evaluations',
  },

  {
    key: FilterOptions.DiscoverConversations,
    translationKey: 'pages.manage-coworker.tabs.explore.discover-conversations',
  },
]

export const ExplorePage = () => {
  const [selected, setSelected] = useState(FilterOptions.CurrentEvaluations)
  const coworkerId = useCoworkerId()

  return (
    <>
      <Box
        style={{
          marginTop: spacing200,
          marginBottom: spacing200,
        }}
      >
        <ToggleButtons options={buttons} setSelected={setSelected} selected={selected} />
      </Box>
      {/* TODO: This would work better with the implementation and lazy loading that we have for Tabs,
 so we would have state persinstance and such. Discuss this with Pablo whether we should use the toggle buttons or not
 As it might be interesting there to mimic this behaviour just with a different header structure? */}

      <LazyLoader visible={selected === FilterOptions.CurrentEvaluations}>
        <ReviewEvaluatedInteractions coworkerId={coworkerId} />
      </LazyLoader>

      <LazyLoader visible={selected === FilterOptions.DiscoverConversations}>
        <EmbedReport reportName="top-level-dashboard" pageTitle={'Discover conversations'} />
      </LazyLoader>
    </>
  )
}
