import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const data = [
  {
    name: 'Your AHT',
    talk: 30,
    hold: 50,
    acw: 20,
  },
  {
    name: 'Benchmark AHT',
    talk: 20,
    hold: 70,
    acw: 10,
  },
]

const generateRandomData = () => {
  const data = []
  for (let i = 2; i <= 30; i++) {
    data.push({
      date: `2024-09-${i.toString().padStart(2, '0')}`,
      talk: Math.floor(Math.random() * 101) + 100,
      hold: Math.floor(Math.random() * 101) + 100,
      acw: Math.floor(Math.random() * 101) + 100,
    })
  }
  return data
}

const lineData = [...generateRandomData()]

const AhtGraph = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="talk" stackId="a" fill={'#489CE3'} />
        <Bar dataKey="hold" stackId="a" fill={'#CCCCCC'} />
        <Bar dataKey="acw" stackId="a" fill={'#003E72'} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export const AhtLineGraph = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart width={500} height={300} data={lineData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area dataKey="talk" stackId="a" fill={'#489CE3'} />
        <Area dataKey="hold" stackId="a" fill={'#CCCCCC'} />
        <Area dataKey="acw" stackId="a" fill={'#003E72'} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AhtGraph
