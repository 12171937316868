import { Box } from '@mantine/core'
import { useState } from 'react'

import { spacing100 } from 'styles/tokens/insikt.tokens'

import { LazyLoader } from 'components/composites/Shared/LazyLoader'
import ToggleButtons, {
  ButtonDefinition,
} from 'components/composites/Shared/ToggleButtons/ToggleButtons.component'
import { EmbedReport } from 'components/features/EmbedReport'

const enum FilterOptions {
  Overview = 'overview',
  Comparison = 'comparison',
}

const buttons: ButtonDefinition[] = [
  {
    key: FilterOptions.Overview,
    translationKey: 'features.teamlead.coworker-reports.overview',
  },
  {
    key: FilterOptions.Comparison,
    translationKey: 'features.teamlead.coworker-reports.comparison',
  },
]

const pageTitle = 'Coworker Reports Page'

export const CoworkerReportsPage = () => {
  const [selected, setSelected] = useState(FilterOptions.Overview)

  // TODO: Unify with the Team sales page? Duplicate code a bit
  return (
    <>
      <Box
        style={{
          marginTop: spacing100,
          marginBottom: spacing100,
        }}
      >
        <ToggleButtons options={buttons} setSelected={setSelected} selected={selected} />
      </Box>
      <LazyLoader visible={selected === FilterOptions.Overview}>
        <EmbedReport reportName={`coworker-overview`} pageTitle={pageTitle} />
      </LazyLoader>
      <LazyLoader visible={selected === FilterOptions.Comparison}>
        <EmbedReport reportName={`coworker-comparison`} pageTitle={pageTitle} />
      </LazyLoader>
    </>
  )
}
