import Button from '@ingka/button'
import Select, { Option } from '@ingka/select'
import informationCircle from '@ingka/ssr-icon/paths/information-circle'
import Text from '@ingka/text'
import { space25, space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { colourGreyNeutral2 } from 'styles/tokens/insikt.tokens'
import { colourBrandBlue, colourBrandYellow } from 'styles/tokens/oldSkapa.tokens'

import AhtGraph, { AhtLineGraph } from 'components/composites/Graphs/ahtGraph.component'
import AptitudeGraph from 'components/composites/Graphs/aptitudeGraph.component'
import RepeatContactGraph from 'components/composites/Graphs/repeatContactGraph.component'
import { Widget } from 'components/composites/Shared/Widget'

import EfficiencyInfoModal from './InfoModal.component'

enum EfficiencyMetrics {
  AHT = 'aht',
  RepeatContact = 'rc',
  Aptitude = 'aptitude',
}

const EfficencyPanel = () => {
  const [activeGraph, setActiveGraph] = useState(EfficiencyMetrics.AHT)
  const [showModal, handleShowModal] = useDisclosure()
  const { t } = useTranslation()

  return (
    <Widget style={{ overflow: 'hidden', backgroundColor: colourGreyNeutral2 }}>
      <Flex direction={'column'} p={space100} gap={space100}>
        <Flex justify={'space-between'} align={'center'}>
          <Text headingSize="l">{t('features.efficiency.panel-title')}</Text>
          <Flex gap={space50}>
            <Button small ssrIcon={informationCircle} onClick={() => handleShowModal.open()}>
              {t('features.efficiency.info')}
            </Button>
          </Flex>
        </Flex>

        <Text bodySize="l">{t('features.efficiency.panel-description')}</Text>

        <Flex align={'end'} gap={space50}>
          <Select style={{ minWidth: 200 }} label={'Skill'} id="test" defaultValue={'Res. Gen.'}>
            <Option
              name={'⭐️ Main skill: Res General (Voice) [152 interactions]'}
              value="Res. Gen."
            />
            <Option name={'Sal. Gen.'} value="Sal. Gen. [72 interactions]" />
          </Select>
        </Flex>

        <Flex direction={'row'} gap={space50}>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.AHT)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'AHT'}
              value={7}
              target={1}
              potential={10}
              capacity={6}
              measurement=" min"
              highestPotential
              adviceKey={'features.efficiency.aht-advice'}
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'aht' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.RepeatContact)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'Repeat contact'}
              value={10}
              target={1}
              potential={8}
              capacity={4}
              measurement="%"
              adviceKey="features.efficiency.repeat-advice"
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'rc' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
          <Flex
            w={'100%'}
            onClick={() => setActiveGraph(EfficiencyMetrics.Aptitude)}
            direction={'column'}
            gap={space25}
            style={{ cursor: 'pointer' }}
          >
            <MetricWidget
              title={'Aptitude'}
              value={90}
              target={92}
              potential={10}
              capacity={6}
              measurement="%"
              adviceKey={'features.efficiency.aptitude-above-target'} // if value is above 83% then show the target reached message 'features.efficiency.aptitude-above-target' otherwise show 'features.efficiency.aptitude-advice'
            />
            <span
              style={{
                backgroundColor: colourBrandBlue,
                width: '100%',
                height: 8,
                opacity: activeGraph === 'aptitude' ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            />
          </Flex>
        </Flex>
        <Widget
          border
          style={{ width: '100%', display: 'flex', justifyContent: 'center', height: 400 }}
        >
          {activeGraph === EfficiencyMetrics.AHT && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100 }}>
                {t('features.efficiency.aht-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'}>
                <Flex w={'50%'} h={'100%'}>
                  <AhtGraph />
                </Flex>
                <Flex w={'50%'} h={'100%'}>
                  <AhtLineGraph />
                </Flex>
              </Flex>
            </Flex>
          )}
          {activeGraph === EfficiencyMetrics.RepeatContact && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100 }}>
                {t('features.efficiency.repeat-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'}>
                <RepeatContactGraph />
              </Flex>
            </Flex>
          )}
          {activeGraph === EfficiencyMetrics.Aptitude && (
            <Flex direction={'column'} w={'100%'} h={'100&'}>
              <Text style={{ marginBottom: space100 }}>
                {t('features.efficiency.aptitude-subtitle')}
              </Text>
              <Flex w={'100%'} h={'100%'}>
                <AptitudeGraph />
              </Flex>
            </Flex>
          )}
        </Widget>
      </Flex>
      <EfficiencyInfoModal handleClose={handleShowModal.close} showModal={showModal} />
    </Widget>
  )
}

const MetricWidget = ({
  title,
  value,
  target,
  potential,
  capacity,
  measurement = '',
  adviceKey,
  highestPotential,
}: {
  title: string
  value?: number | string
  potential?: number
  target?: number
  capacity?: number
  measurement?: string
  adviceKey?: string
  highestPotential?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Widget
      border
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text
          headingSize="s"
          style={{
            width: '100%',
          }}
        >
          {title}
        </Text>

        <Text
          headingSize="s"
          style={{
            backgroundColor: colourBrandYellow,
            padding: space25,
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            opacity: highestPotential ? 1 : 0,
          }}
        >
          {t('features.efficiency.highest-potential')}
        </Text>
      </Flex>

      <Text
        headingSize="m"
        style={{
          marginTop: space100,
          backgroundColor: colourGreyNeutral2,
          padding: space25,
          marginBottom: space100,
        }}
      >
        {value ? value + measurement : 'N/A'}
      </Text>

      <Trans
        i18nKey={adviceKey}
        values={{ metric: title, target: target, potential: potential, capacity: capacity }}
        components={{
          bold: <Text style={{ display: 'inline', fontWeight: 'bold' }} />,
          text: (
            <Text
              style={{
                border: '1px dashed black',
                padding: space50,
                backgroundColor: '#F1F4FE',
              }}
            />
          ),
        }}
      />
    </Widget>
  )
}

export default EfficencyPanel
