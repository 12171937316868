import { Skeleton } from '@mantine/core'
import { FC } from 'react'

import { RTFTextEditor } from '../RTFTextEditor'
import { isJsonString } from '../utils'
import { useViewOnlyEditor } from './RTFViewBox.hooks'

interface Props {
  text: string | undefined
  fallbackText?: string
}

export const RTFViewBox: FC<Props> = ({ text, fallbackText = '' }: Props) => {
  text = text || fallbackText
  const newContent = isJsonString(text) ? JSON.parse(text!) : text
  const editor = useViewOnlyEditor(newContent)

  if (!editor) return <Skeleton />

  return <RTFTextEditor editor={editor} />
}
