import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import Table, { TableBody, TableHeader } from '@ingka/table'
import Text from '@ingka/text'
import { space100, space550 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { MissionStatus } from 'lib/types/mission.types'
import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useLastCompletedCoachingSession } from 'hooks/coachingSessions'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useGetTeamleadCustomerComments } from 'state/slices/api'
import { useCSATRollingData } from 'state/slices/api/modules/surveyMetric/surveyMetric.hooks'

import { Widget } from 'components/composites/Shared/Widget/Widget.styling'
import { Heading } from 'components/primitives/Text'

import CoworkerPhoto from '../CoworkerPhoto/CoworkerPhoto.component'
import { CoworkerTableProps, TableRowProps } from './CoworkersTable.types'

const CoworkersTable: React.FC<CoworkerTableProps> = ({ coworkers }) => {
  const { t } = useTranslation()

  // We need to filter the coworkers based on the coaching session data stored on the coworker object itself.
  // It's a bit messy and we can figure out another way of doing this in the future, but for now I believe this is the easiest way to do it.
  const coworkersWithDays =
    coworkers.map((coworker) => {
      const lastCoachingSession = CoachingSessionHelper.getPreviousCoachingSession(
        coworker.coachingSessions
      )

      if (!lastCoachingSession) {
        return {
          ...coworker,
          days: -1,
        }
      }

      const lastCoachingSessionDate = dayjs(lastCoachingSession.completedDate)
      const daysSinceLastCoaching = dayjs().diff(lastCoachingSessionDate, 'days')

      return {
        ...coworker,
        days: daysSinceLastCoaching,
      }
    }) ?? []

  const sortedCoworkers = orderBy(coworkersWithDays, 'days', 'desc')

  return (
    <>
      <Widget>
        <Heading>{`${coworkers.length} Co-worker${coworkers.length === 1 ? '' : 's'}`}</Heading>
        <Table fullWidth inset>
          <TableHeader>
            <tr>
              <th style={{ verticalAlign: 'left', textAlign: 'left' }}>
                {t('features.teamlead.coworkers-table.table-headers.name')}
              </th>

              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.manner')}
              </th>
              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.knowledge')}
              </th>
              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.solution')}
              </th>

              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.evaluations')}
              </th>

              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.active-missions')}
              </th>
              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.customer-comments')}
              </th>

              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.days-since-last-coaching')}
              </th>
              <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t('features.teamlead.coworkers-table.table-headers.coworker-overview')}
              </th>
            </tr>
          </TableHeader>
          <TableBody striped>
            {sortedCoworkers.map((coworker) => {
              return <TableRow key={coworker.legacyId} coworker={coworker} active={true} />
            })}
          </TableBody>
        </Table>
      </Widget>
    </>
  )
}

const TableRow: React.FC<TableRowProps> = ({ coworker }) => {
  const { teamleadPath } = useSectionPaths()
  const nav = useNavigate()

  const { computedSurveyValues } = useCSATRollingData(coworker.legacyId)
  const lastCoachingSession = useLastCompletedCoachingSession(coworker.legacyId).data

  const { data: customerComments = [], isLoading: isCustomerCommentsLoading } =
    useGetTeamleadCustomerComments(coworker.legacyId) ?? {}

  return (
    <tr>
      <td>
        <Flex direction={'row'} gap={space100} align={'center'}>
          <CoworkerPhoto coworker={coworker} />
          <Text bodySize="l">{coworker.fullName}</Text>
        </Flex>
      </td>

      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Text headingSize="xs">
          {computedSurveyValues ? computedSurveyValues.percentageManner + '%' : '-'}
        </Text>
      </td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Text headingSize="xs">
          {computedSurveyValues ? computedSurveyValues.percentageKnowledge + '%' : '-'}
        </Text>
      </td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Text headingSize="xs">
          {computedSurveyValues ? computedSurveyValues.percentageSolution + '%' : '-'}
        </Text>
      </td>

      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Button
          small
          style={{ width: space550 }}
          text={<Text>{coworker.recentQAs.QAs.length}</Text>}
          onClick={() => nav(`${teamleadPath}/coworkers/${coworker.legacyId}#explore`)}
          loading={isCustomerCommentsLoading}
        />
      </td>

      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Button
          small
          style={{ width: space550 }}
          text={
            <Text>
              {
                coworker.missions.filter((mission) => mission.status === MissionStatus.Active)
                  .length
              }
            </Text>
          }
          onClick={() => nav(`${teamleadPath}/coworkers/${coworker.legacyId}#missions`)}
          loading={isCustomerCommentsLoading}
        />
      </td>

      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Button
          small
          style={{ width: space550 }}
          text={<Text>{customerComments.length}</Text>}
          onClick={() => nav(`${teamleadPath}/coworkers/${coworker.legacyId}#customer-comments`)}
          loading={isCustomerCommentsLoading}
        />
      </td>
      <td
        style={{
          verticalAlign: 'middle',

          textAlign: 'center',
        }}
      >
        <Text headingSize="xs">
          {lastCoachingSession
            ? dayjs().diff(dayjs(lastCoachingSession?.completedDate), 'days')
            : '-'}
        </Text>
      </td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <Button
          type="tertiary"
          small
          iconOnly
          ssrIcon={arrowRight}
          onClick={() =>
            nav(`${teamleadPath}/coworkers/${coworker.legacyId}#missions`, {
              state: {
                from: teamleadPath,
              },
            })
          }
        />
      </td>
    </tr>
  )
}

export default CoworkersTable
