import chat from '@ingka/ssr-icon/paths/chat'
import checkmarkBox from '@ingka/ssr-icon/paths/checkmark-box'
import clock from '@ingka/ssr-icon/paths/clock'
import magnifyingGlass from '@ingka/ssr-icon/paths/magnifying-glass'
import people from '@ingka/ssr-icon/paths/people'

import { MissionStatus } from 'lib/types/mission.types'

import { useIdGuard } from 'components/composites/Shared/IdGuard/IdGuard.hooks'
import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import { TabDefinitions } from 'components/composites/Shared/TabLayout/TabLayout.types'
import { CoachingSessionPreviewList } from 'components/features/CoachingSessions/CoachingSessionPreviewList/CoachingSessionPreviewList.component'
import EfficencyPanel from 'components/features/Efficiency/EfficiencyPanel.component'
import { MissionPreviewList } from 'components/features/Missions/MissionPreviewList'
import { CustomerCommentsPage } from 'components/pages/shared/CustomerCommentOverviewPage'

import { CoworkerReportsPage } from '../../CoworkerReportsPage/CoworkersReportsPage.component'
import { ExplorePage } from '../../ExplorePage/ExplorePage.component'
import { TabKeys } from './TeamleadTabs.constants'

type TeamleadTabsProps = {
  coworkerId: string
}

const TeamleadTabs: React.FC<TeamleadTabsProps> = ({ coworkerId }) => {
  const showEfficiency = useIdGuard(coworkerId ?? '', 'efficiency')
  const tabsArray: TabDefinitions = [
    {
      key: TabKeys.Efficiency,
      content: <EfficencyPanel />,
      tKeyForTitle: 'features.efficiency.title',
      icon: clock,
      disabled: {
        isDisabled: !showEfficiency,
        tooltipText: 'features.efficiency.disabled-tooltip',
      },
    },
    {
      key: TabKeys.Explore,
      content: <ExplorePage />,
      tKeyForTitle: 'pages.manage-coworker.tabs.evaluations.title',
      icon: magnifyingGlass,
    },
    {
      key: TabKeys.Missions,
      content: (
        <MissionPreviewList
          availableStatuses={[MissionStatus.Draft, MissionStatus.Active, MissionStatus.Completed]}
        />
      ),
      tKeyForTitle: 'pages.manage-coworker.tabs.missions.title',
      icon: checkmarkBox,
    },

    {
      key: TabKeys.CustomerComments,
      content: <CustomerCommentsPage />,
      tKeyForTitle: 'pages.manage-coworker.tabs.customer-comments.title',
      icon: chat,
    },
    {
      key: TabKeys.CoachingSession,
      content: <CoachingSessionPreviewList coworkerId={coworkerId} />,
      tKeyForTitle: 'pages.manage-coworker.tabs.coaching-sessions.title',
      icon: people,
    },
    {
      key: TabKeys.CoworkerReports,
      content: <CoworkerReportsPage />,
      tKeyForTitle: 'pages.manage-coworker.tabs.reports.title',
      icon: people,
      disabled: {
        tooltipText: 'common.comming-soon',
        isDisabled: process.env.REACT_APP_ENV !== 'dev',
      },
    },
  ]

  return (
    <TabLayout
      tabDefinitions={tabsArray}
      analyticsDescription="Custom event for tracking when a teamlead switches between tabs on coworker page."
    />
  )
}

export default TeamleadTabs
