import { SurveyMetricDataset } from 'lib/types/surveyMetric.types'

import { apiSlice } from '../../api.slice'

export const surveyMetricSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyMetricsByCoworkerId: builder.query<SurveyMetricDataset, string>({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/surveys`,
        method: 'GET',
      }),
      providesTags: (result, error, coworkerId) => [{ type: 'SurveyMetric', id: coworkerId }],
    }),
  }),
})

export const { useGetSurveyMetricsByCoworkerIdQuery: useSurveyMetrics } = surveyMetricSlice
