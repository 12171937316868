import InlineMessage from '@ingka/inline-message'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCoworkers } from 'state/slices/api'
import { RootState } from 'state/store'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CoworkersOverviewProps } from './CoworkersOverview.types'
import CoworkersTable from './CoworkersTable/CoworkerTable.component'

const CoworkersOverview: React.FC<CoworkersOverviewProps> = ({ legacyId }) => {
  const { t } = useTranslation()

  const {
    data: coworkers,
    isError: isErrorCoworkers,
    isFetching: isFetchingCoworkers,
    isSuccess: isSuccessCoworkers,
  } = useCoworkers(legacyId)

  const isDataReady = useSelector((state: RootState) => {
    // This logic is a bit tricky, and we should look into understanding it better
    // But essentially what we're doing here, is getting the Redux Store states for quality assessments and coachoing sessions
    // And then we're getting the length of the assessments from the state which corresponds to the length of coworkers which we can then use
    // to determine whether or not to render the table
    const assessmentState = state.api.provided['QualityAssessment']
    const coachingSessionState = state.api.provided['Coaching Session']
    const commentsState = state.api.provided['Customer Comment']
    const missionState = state.api.provided['Mission']
    const surveyState = state.api.provided['SurveyMetric']

    const isAllStatesFetched =
      assessmentState && coachingSessionState && commentsState && missionState && surveyState

    return (
      coworkers &&
      isAllStatesFetched &&
      Object.keys(missionState).length === coworkers?.activeCoworkers.length
    )
  })

  return (
    <>
      {isFetchingCoworkers && !isSuccessCoworkers && (
        <LoadingIndicator message={t('loading-messages.fetching-coworker-profiles')} />
      )}
      {isErrorCoworkers && <UnexpectedErrorMessage />}
      {!isFetchingCoworkers && isSuccessCoworkers && (
        <>
          {/** Case when user has access to no co-workers */}
          {coworkers.activeCoworkers.length > 0 ? (
            isDataReady && <CoworkersTable coworkers={coworkers.activeCoworkers} />
          ) : (
            <InlineMessage
              variant="informative"
              title={t('features.coworker.coworkers-overview.errors.no-coworkers.title')}
              body={t('features.coworker.coworkers-overview.errors.no-coworkers.message')}
            />
          )}
        </>
      )}
    </>
  )
}

export default CoworkersOverview
