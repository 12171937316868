import Button from '@ingka/button'
import { space100 } from '@ingka/variables'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

type FilterMissionsButtonsProps = {
  missionsStatus: MissionStatus[]
  selected: MissionStatus
  setSelected: (status: MissionStatus) => void
}

const FilterMissionsButtons: React.FC<FilterMissionsButtonsProps> = ({
  missionsStatus,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation()

  const handleSelected = (status: MissionStatus) => {
    setSelected(status)
  }

  return (
    <>
      {missionsStatus?.map((status, id) => {
        return (
          <Button
            key={id}
            aria-pressed="true"
            small
            type={selected === status ? 'image-overlay' : 'secondary'}
            aria-activedescendant={selected}
            className={selected === status ? 'selected' : ''}
            text={t(`features.mission.preview.${status}`)}
            onClick={() => handleSelected(status)}
            style={{
              marginRight: space100,
            }}
          />
        )
      })}
    </>
  )
}

export default FilterMissionsButtons
