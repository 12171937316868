// TODO: Either change the names (only nummerical not allowed in enums and require bracket access in JS) or change from object to individual variables

export const colourCategorical = {
  DarkBlue: '#0058A3',
  Red: '#CC0008',
  Green: '#00853E',
  Orange: '#F26A2F',
  LightBlue: '#007CC1',
  Pink: '#E52F7E',
}

export const colourGreyNeutral = {
  1: '#FFFFFF',
  2: '#F5F5F5',
  3: '#DFDFDF',
  4: '#CCCCCC',
  5: '#929292',
  6: '#484848',
  7: '#111111',
}

export const colourGreyNeutral1 = '#FFFFFF'
export const colourGreyNeutral2 = '#F5F5F5'
export const colourGreyNeutral3 = '#DFDFDF'
export const colourGreyNeutral4 = '#CCCCCC'
export const colourGreyNeutral5 = '#929292'
export const colourGreyNeutral6 = '#484848'
export const colourGreyNeutral7 = '#111111'

// Remove?
export const textColour = {
  1: '#111111',
  2: '#484848',
  3: '#767676',
  4: '#929292',
  5: '#FFFFFF',
}

export const textColour1 = '#111111' // Selected tab
export const textColour2 = '#484848'
export const textColour3 = '#767676' // This goes to tabs - not selected + 50% opacity if disabled
export const textColour4 = '#929292'
export const textColour5 = '#FFFFFF'

export const SemanticColour = {
  Informative: '#0058A3',
  Positive: '#0A8A00',
  Caution: '#F26A2F',
  Negative: '#E00751',
}

export const BrandColour = {
  Yellow: '#FFDB00',
  Blue: '#0058A3',
  Family: '#007CC1',
  Business: '#DEBC9C',
}

export const lightBorderColor = '#DFDFDF'

/** RGBA-ized version of Semantic colors  */
export const SemanticColourRGBA = {
  Informative: 'rgba(0, 88, 163, 0.1)',
  Positive: 'rgba(10, 138, 0, 0.1)',
  Caution: 'rgba(242, 106, 47, 0.1)',
  Negative: 'rgba(224, 7, 81, 0.1)',
}

// Same as radius - maybe we want to use the lower once, since we need to use spacing[25] which looks kinda weird
export const spacing = {
  25: '4px',
  50: '8px',
  100: '16px',
  150: '24px',
  200: '32px',
  250: '40px',
}

export const spacing25 = '4px'
export const spacing50 = '8px'
export const spacing75 = '12px'
export const spacing100 = '16px'
export const spacing150 = '24px'
export const spacing200 = '32px'
export const spacing250 = '40px'

// TODO: Remove this? I think that the other implementation is a bit better
export const radius = {
  25: '4px',
  50: '8px',
  100: '16px',
  150: '24px',
  200: '32px',
}

export const radius25 = '4px'
export const radius50 = '8px'
export const radius100 = '16px'
export const radius150 = '24px'
export const radius200 = '32px'

// TODO: Talk with Pablo and get better names and all the font sizes we need
export const fontSizeNormal = '1rem'
export const fontSizeSmall = '0.625rem'

////-----------------------------------------////
//                 Old tokens                  //
////-----------------------------------------////

export const colourPrimary = {
  main: '#0058a3',
  light: '#1A72BD',
  veryLight: '#338BD6',
  dark: '#003F8A',
  veryDark: '#002570',
  text: '#fff',
}

export const colourSecondary = {
  main: '#ffdb00',
  light: '#ffe433',
  veryLight: '#ffeb66',
  dark: '#e6c700',
  veryDark: '#ccb100',
  text: '#fff',
}

/** 500ms */
export const debounceTimeDefault = 500

/** #F5F5F5 */
export const backgroundColourDefault = '#F5F5F5'

/** #FFF */
export const backgroundColourWhite = '#FFF'

export const shadowDefaultSize = '0px 2px 4px rgba(0,0,0,.1)'
export const shadowRaisedSize = '0px 4px 8px rgba(0,0,0,.2)'

/** Colour used to represent a co-worker */
export const coworkerColour = 'rgb(112, 164, 140)'

/** Colour used to represent a team */
export const teamColour = 'rgb(57, 88, 113)'

/* Colour used to represent a target */
export const targetColour = 'rgb(168, 79, 79)'

/** Colour used to represent a comparison */
export const comparisonColour = 'rgb(204, 188, 69)'

/** Colour used to represent status of assessments done in the <Tag /> component  */
export const missingAssessmentTagBgColour = 'RGB(255, 232, 204)' // light orange
