import Button from '@ingka/button'
import {
  fontSize400,
  fontSize700,
  fontWeightBold,
  space50,
  space100,
  space200,
} from '@ingka/variables'
import { Flex } from '@mantine/core'
import dayjs from 'dayjs'
import { useState } from 'react'

import { useAdminStats } from 'hooks/realtime/useAdminStats.hooks'

import { backgroundColourWhite } from 'styles/tokens/insikt.tokens'
import {
  colourBrandBlue,
  colourButtonPrimaryEmphasisedDefault,
  colourButtonPrimaryEmphasisedHover,
  colourNeutralGrey300,
} from 'styles/tokens/oldSkapa.tokens'

import { KPI } from 'components/features/SurveyMetrics/CoworkerMainKPIs.component'
import { PageHeader } from 'components/layout/PageHeader'
import { Paragraph } from 'components/primitives/Text'
import styled, { css } from 'styled-components'

const AdminStatsPage = () => {
  const stats = useAdminStats()
  const [selectedStatsType, setSelectedStatsType] = useState('cs')

  const totalComments = stats.comments?.length ?? 0
  const csat =
    stats.comments?.reduce((acc, comment) => {
      return acc + comment.custSat
    }, 0) ?? 0

  const manner =
    stats.comments?.reduce((acc, comment) => {
      return acc + Number(comment.manner)
    }, 0) ?? 0

  const knowledge =
    stats.comments?.reduce((acc, comment) => {
      return acc + Number(comment.knowledge)
    }, 0) ?? 0

  const solution =
    stats.comments?.reduce((acc, comment) => {
      return acc + Number(comment.solution)
    }, 0) ?? 0

  const avgCsat = csat / totalComments
  const avgManner = manner / totalComments
  const avgKnowledge = knowledge / totalComments
  const avgSolution = solution / totalComments

  return (
    <Flex data-testid="page-admin-stats" direction={'column'} align={'start'}>
      <PageHeader title="Admin stats dashboard" backHref="/admin" />

      <Flex align={'center'} direction={'row'} mb={space200} mt={space200}>
        <Paragraph
          style={{
            fontSize: fontSize700,
            color: colourBrandBlue,
            fontWeight: fontWeightBold,
          }}
        >
          {stats.users?.length}
        </Paragraph>

        <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
          {'users are registered in Insikt and'}
        </Paragraph>
        <Paragraph
          style={{
            fontSize: fontSize700,
            color: colourBrandBlue,
            fontWeight: fontWeightBold,
            marginLeft: space50,
          }}
        >
          {
            stats.users?.filter((user) => {
              return user.lastLogin && dayjs(user.lastLogin).isSame(dayjs(), 'day')
            }).length
          }
        </Paragraph>
        <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
          {' has been active today 🦄'}
        </Paragraph>
      </Flex>

      <Flex
        gap={space50}
        justify={'center'}
        align={'center'}
        style={{
          backgroundColor: backgroundColourWhite,
          paddingLeft: space100,
          paddingRight: space100,
          paddingTop: space50,
          paddingBottom: space50,
          flex: 0,
          borderRadius: '64px',
        }}
      >
        <CustomButton
          onClick={() => setSelectedStatsType('cs')}
          $selected={selectedStatsType === 'cs'}
          type="plain"
          small
        >
          {'Coaching sessions'}
        </CustomButton>

        <CustomButton
          onClick={() => setSelectedStatsType('missions')}
          $selected={selectedStatsType === 'missions'}
          type="plain"
          small
        >
          {'Missions'}
        </CustomButton>
        <CustomButton
          onClick={() => setSelectedStatsType('customerComments')}
          $selected={selectedStatsType === 'customerComments'}
          type="plain"
          small
        >
          {'Customer comments'}
        </CustomButton>
      </Flex>

      {selectedStatsType === 'cs' && (
        <Flex direction={'column'} mt={space200}>
          <Flex align={'center'} direction={'row'}>
            <Paragraph style={{ marginRight: space50, fontSize: fontSize400 }}>
              {'A total of '}
            </Paragraph>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.coachingSessions?.length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'coaching sessions has been created ⭐️'}
            </Paragraph>
          </Flex>
          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.coachingSessions?.filter((cs) => cs.status === 'planned').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'are ready for coaching 🚀'}
            </Paragraph>
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {
                stats.coachingSessions?.filter(
                  (cs) => cs.status === 'completed' || cs.status === 'signed'
                ).length
              }
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'of them has been completed ✅'}
            </Paragraph>
          </Flex>
        </Flex>
      )}

      {selectedStatsType === 'missions' && (
        <Flex direction={'column'} mt={space200}>
          <Flex align={'center'} direction={'row'}>
            <Paragraph style={{ marginRight: space50, fontSize: fontSize400 }}>
              {'A total of '}
            </Paragraph>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.missions?.length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'missions has been created ⭐️'}
            </Paragraph>
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.missions?.filter((mission) => mission.status === 'draft').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'are drafts 🚀'}
            </Paragraph>
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.missions?.filter((mission) => mission.status === 'active').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'are being worked on 📊'}
            </Paragraph>
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.missions?.filter((mission) => mission.status === 'completed').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'of them has been completed ✅'}
            </Paragraph>
          </Flex>
        </Flex>
      )}

      {selectedStatsType === 'customerComments' && (
        <Flex direction={'column'} mt={space200}>
          <Flex align={'center'} direction={'row'}>
            <Paragraph style={{ marginRight: space50, fontSize: fontSize400 }}>
              {'A total of '}
            </Paragraph>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.comments?.length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'comments are forwarded to coworkers'}
            </Paragraph>
          </Flex>

          <Paragraph style={{ fontSize: fontSize400 }}>
            {'Average scores for forwarded comments:'}
          </Paragraph>

          <Flex align={'center'} direction={'row'} gap={space50}>
            <KPI title="CSAT" value={Number(avgCsat.toFixed(2))} />
            <KPI title="Manner" value={Number(avgManner.toFixed(2))} />
            <KPI title="Knowledge" value={Number(avgKnowledge.toFixed(2))} />
            <KPI title="Solution" value={Number(avgSolution.toFixed(2))} />
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.comments?.filter((comment) => comment.status === 'new').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'comments are saved and visible to the coworker'}
            </Paragraph>
          </Flex>

          <Flex align={'center'} direction={'row'}>
            <Paragraph
              style={{
                fontSize: fontSize700,
                color: colourBrandBlue,
                fontWeight: fontWeightBold,
              }}
            >
              {stats.comments?.filter((comment) => comment.status === 'dismissed').length}
            </Paragraph>
            <Paragraph style={{ marginLeft: space50, fontSize: fontSize400 }}>
              {'comments has been dismissed by the coworker'}
            </Paragraph>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const CustomButton = styled(Button)<{ $selected?: boolean }>`
  padding-left: ${space100};
  padding-right: ${space100};
  &:hover {
    background-color: ${colourNeutralGrey300};
  }

  transition-duration: 0.3s;

  ${(props) =>
    props.$selected &&
    css`
      background-color: ${colourButtonPrimaryEmphasisedDefault};
      color: ${backgroundColourWhite};
      &:hover {
        background-color: ${colourButtonPrimaryEmphasisedHover};
      }
    `}
`

export default AdminStatsPage
