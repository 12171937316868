import SSRIcon from '@ingka/ssr-icon'
import note from '@ingka/ssr-icon/paths/note'
import { space200 } from '@ingka/variables'
import { Box, Group } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { radius25, spacing50, spacing75, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'
import { RTFViewBox } from 'components/shared/RtfFeatures/RTFViewBox/RTFViewBox.component'
type SessionCommentProps = {
  comment: string
}

/** Added html-react-parser package to render RTF output, since 'dagerouslySetInnerHTML' is not ideal  */
const SessionComment: React.FC<SessionCommentProps> = ({ comment }) => {
  const { t } = useTranslation()

  return (
    <Widget border style={{ marginTop: spacing100 }}>
      <Group gap={spacing50}>
        <SSRIcon paths={note} />
        <SubHeading>{t('features.coaching-session.session-comment.title')}</SubHeading>
      </Group>
      <Box
        mt={spacing75}
        bg={colourNeutralGrey100}
        p={spacing75}
        mih={space200}
        style={{ borderRadius: radius25 }}
      >
        <RTFViewBox
          text={comment}
          fallbackText={'features.coaching-session.session-comment.no-comment'}
        />
      </Box>
    </Widget>
  )
}

export default SessionComment
