import SSRIcon from '@ingka/ssr-icon'
import person from '@ingka/ssr-icon/paths/person'

import { Coworker } from 'lib/types/coworker.types'
import { TeamleadUser } from 'lib/types/user.types'

import { colourBrandBlue } from 'styles/tokens/oldSkapa.tokens'
interface ProfilePhotoProps {
  coworker?: Coworker
  teamleadUser?: TeamleadUser
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ coworker, teamleadUser }) => {
  const getPhotoSource = () => {
    if (coworker && coworker.photoData?.photo) {
      return {
        src: coworker.photoData?.photo,
        alt: coworker.fullName || '',
        title: coworker.fullName || '',
        style: {
          width: '180px',
          height: '180px',
          borderRadius: '100%',
          border: `5px solid ${colourBrandBlue}`,
          objectFit: 'cover',
          aspectRatio: 1,
          perspective: '1px',
        } as React.CSSProperties,
      }
    } else if (teamleadUser && teamleadUser.photoData?.photo) {
      return {
        src: teamleadUser.photoData?.photo,
        alt: teamleadUser.name || '',
        title: teamleadUser.name || '',
        style: {
          width: '56px',
          height: '56px',
          borderRadius: '100%',
          objectFit: 'cover',
          aspectRatio: 1,
          perspective: '1px',
        } as React.CSSProperties,
      }
    } else {
      return null
    }
  }

  const renderIcon = () => {
    if (teamleadUser && !teamleadUser.photoData?.photo) {
      return {
        paths: person,
        minWidth: '48px',
        minHeight: '48px',
        borderRadius: '100%',
      }
    } else {
      return {
        width: '180px',
        height: '180px',
        color: colourBrandBlue,
        borderRadius: '100%',
        border: `5px solid ${colourBrandBlue}`,
      }
    }
  }

  const photoProps = getPhotoSource()
  return (
    <>
      {photoProps ? (
        <img {...photoProps} alt={photoProps.alt} />
      ) : (
        <SSRIcon paths={person} style={renderIcon()} />
      )}
    </>
  )
}
export default ProfilePhoto
