import FormField from '@ingka/form-field'
import InlineMessage from '@ingka/inline-message'
import Select, { Option } from '@ingka/select'
import { space200, space400 } from '@ingka/variables'
import { Box, Grid } from '@mantine/core'
import { orderBy } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { QaMetricId } from 'config/domain/qa.config'

import { Mission } from 'lib/types/mission.types'
import { QaType } from 'lib/types/qualityAssessment.types'

import { useFormatDate } from 'hooks/formatting'
import { useCurrentQaDataForCoworker } from 'hooks/qualityAssssments/useCurrentQaDataForCoworker.hooks'

import { useCoworker } from 'state/slices/api'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Widget } from 'components/composites/Shared/Widget'
import { MissionDraftEditor } from 'components/features/Missions/MissionDraftEditor'
import { MissionQaMetricsTable } from 'components/features/Missions/MissionQaMetricsTable'
import { MissionQaMetricsFormProps } from 'components/features/Missions/MissionQaMetricsTable/MissionQaMetricsTable.types'
import QaNotes from 'components/features/QualityAssessments/QaNotes.component'
import { Label, SubHeading } from 'components/primitives/Text'

import { useCurrentSessionData } from './ReviewMissions.hooks'

type ReviewMissionsProps = {
  coworkerId: string
}

const ReviewMissions: React.FC<ReviewMissionsProps> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const { coachingSessionId } = useParams()
  const qaSelectForm = useForm<MissionQaMetricsFormProps>({
    defaultValues: {
      qaId: undefined,
    },
  })

  const qaData = useCurrentQaDataForCoworker(coworkerId)
  const formatDate = useFormatDate()

  const { getValues, resetField } = qaSelectForm
  const { qaId } = getValues()
  const { data: coworker } = useCoworker(coworkerId)

  const qaMetricsBoxRef = useRef<HTMLDivElement>(null)

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [selectedCheckboxId, setSelectedCheckboxId] = useState<string | null>(null)
  const [mission, setCurrentMission] = useState<Mission>()

  const [assessmentType, setAssessmentType] = useState<QaType>()

  const {
    assessments: assessmentsData,
    missions: missionsData,
    sac,
    sales,
    resolution,
  } = useCurrentSessionData(coworkerId)

  useEffect(() => {
    if (assessmentsData.length > 0 && assessmentType === undefined) {
      setAssessmentType(
        orderBy(
          [
            {
              id: 'QAR',
              assessmentNr: resolution.assessments.length,
              missionNr: resolution.missions.length,
            },
            {
              id: 'QAS',
              assessmentNr: sales.assessments.length,
              missionNr: sales.missions.length,
            },
            {
              id: 'QSA',
              assessmentNr: sac.assessments.length,
              missionNr: sac.missions.length,
            },
          ],
          ['assessmentNr', 'missionNr'],
          ['desc', 'desc']
        )[0].id as QaType
      )
    } else if (assessmentType === undefined) {
      setAssessmentType(QaType.QAR)
    }
  }, [assessmentType, sac, sales, resolution, assessmentsData])

  const handleTypeChanged = useCallback(
    (value: QaType) => {
      if (!(value === QaType.QAR || value === QaType.QAS || value === QaType.QSA)) {
        return
      }
      setAssessmentType(value)
      resetField('qaId')
    },
    [resetField]
  )

  return (
    <>
      {coworker && qaData && (
        <>
          {/* Assessment type dropdown and QA stats column*/}
          <Widget>
            <Grid>
              <Grid.Col span={{ base: 7, md: 7, sm: 12 }}>
                <>
                  <FormField
                    data-testid="form-field-assessment-type"
                    fieldHelper={{
                      msg: (
                        <Label>
                          {t('features.mission.create-mission.fields.assessment-type.hint')}
                          <br />
                          {t(
                            `features.mission.create-mission.fields.assessment-type.${
                              // TODO: This does not work as we switched how qaData is structured
                              // Since for some reason we are not returning coaching sessions on coworker, cant really check that without loading all coaching sessions,
                              // Which seems like a bit of an overkill. Think what to do here
                              qaData.isFirstSession ? 'date-hint-initial' : `date-hint`
                            }`,
                            {
                              startDate: formatDate(qaData.startDate),
                              endDate: formatDate(qaData.endDate),
                            }
                          )}
                        </Label>
                      ),
                    }}
                  >
                    <Select
                      label={t('features.mission.create-mission.fields.assessment-type.title')}
                      id="metric"
                      data-testid="input-field-assessment-type"
                      value={assessmentType}
                      onChange={(e) => handleTypeChanged(e.target.value as QaType)}
                    >
                      <Option
                        name={`${t('qa.assessment-type.QAR')} (${resolution.assessments.length} ${t(
                          'common.quality-assessments'
                        )}, ${resolution.missions.length} ${t('common.missions')})`}
                        value="QAR"
                      />
                      <Option
                        name={`${t('qa.assessment-type.QAS')} (${sales.assessments.length} ${t(
                          'common.quality-assessments'
                        )}, ${sales.missions.length} ${t('common.missions')})`}
                        value="QAS"
                      />
                      <Option
                        name={`${t('qa.assessment-type.QSA')} (${sac.assessments.length} ${t(
                          'common.quality-assessments'
                        )}, ${sac.missions.length} ${t('common.missions')})`}
                        value="QSA"
                      />
                    </Select>
                  </FormField>
                  <Box mb={space200}>
                    {assessmentsData.length === 0 && (
                      <InlineMessage
                        variant="cautionary"
                        title={t('features.mission.warnings.no-assessments')}
                      />
                    )}
                    {missionsData.length === 0 && (
                      <InlineMessage
                        variant="cautionary"
                        title={t('features.mission.warnings.no-missions')}
                      />
                    )}
                  </Box>
                </>

                {assessmentType ? (
                  <MissionQaMetricsTable
                    coworkerId={coworker.legacyId}
                    assessmentType={assessmentType}
                    coachingSessionId={coachingSessionId!}
                    qaSelectForm={qaSelectForm}
                    mission={mission}
                    setCurrentMission={setCurrentMission}
                    selectedCheckboxId={selectedCheckboxId}
                    setSelectedCheckboxId={setSelectedCheckboxId}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                  />
                ) : (
                  <LoadingIndicator />
                )}
              </Grid.Col>

              <Grid.Col
                span={{ base: 5, md: 5, sm: 12 }}
                pl={space200}
                style={{ position: 'sticky', top: 0 }}
                ref={qaMetricsBoxRef}
              >
                {assessmentType && (
                  <div style={{ position: 'sticky', top: space400 }}>
                    {qaId ? (
                      <SubHeading style={{ marginBottom: space200 }}>
                        {t(`qa.${qaId}`) as string}
                      </SubHeading>
                    ) : (
                      <InlineMessage
                        variant="cautionary"
                        title={t('features.mission.create-mission.avg-score-stats.missing-metric')}
                      />
                    )}
                    {
                      <MissionDraftEditor
                        coworkerId={coworkerId!}
                        coachingSessionId={coachingSessionId!}
                        selectedCheckboxId={selectedCheckboxId as QaMetricId}
                        setIsChecked={setIsChecked}
                      />
                    }
                    <QaNotes coworkerId={coworkerId} assessments={assessmentsData} qaId={qaId} />
                  </div>
                )}
              </Grid.Col>
            </Grid>
          </Widget>
        </>
      )}
    </>
  )
}

export default ReviewMissions
