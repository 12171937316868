import SSRIcon from '@ingka/ssr-icon'
import noteIcon from '@ingka/ssr-icon/paths/note'
import { Box, Group, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { spacing50, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'
import { RTFViewBox } from 'components/shared/RtfFeatures/RTFViewBox/RTFViewBox.component'

type Props = {
  coachingSession: CoachingSession
}

const FormattedCoworkerNote: React.FC<Props> = ({ coachingSession }) => {
  const { t } = useTranslation()
  const coworkerNote = coachingSession.coworkerNote?.note
  const pageType = useCurrentPageType()

  const transKey = `features.coworker-note.warnings.for-${pageType}`

  return (
    <Widget border style={{ marginTop: spacing100 }}>
      <Stack>
        <Group gap={spacing50}>
          <SSRIcon paths={noteIcon} />
          <SubHeading>{t(`${transKey}.title`)}</SubHeading>
        </Group>
        <Box p={spacing50} bg={colourNeutralGrey100}>
          <RTFViewBox text={coworkerNote} fallbackText={t(`${transKey}.no-note`)} />
        </Box>
      </Stack>
    </Widget>
  )
}

export default FormattedCoworkerNote
