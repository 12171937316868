import { useParams } from 'react-router-dom'

export const useTeamleadId = (): string => {
  const { teamleadId } = useParams()

  if (!teamleadId) {
    throw new Error('Cannot find TeamleadID in the route - is this used under right route?')
  }
  return teamleadId
}
