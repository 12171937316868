import Text from '@ingka/text'
import { space25, space50, space100 } from '@ingka/variables'
import { Flex, Grid } from '@mantine/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import { useLastCompletedCoachingSession } from 'hooks/coachingSessions'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useMissions } from 'state/slices/api'
import { useRecentQAs } from 'state/slices/api/modules/qualityAssessment/qualityAssessment.slice'
import { useCSATRollingData } from 'state/slices/api/modules/surveyMetric/surveyMetric.hooks'

import { colourGreyNeutral1, colourGreyNeutral2 } from 'styles/tokens/insikt.tokens'

import { LoadingSkeleton } from 'components/composites/Shared/LoadingSkeleton/LoadingSkeleton.component'
import { Widget } from 'components/composites/Shared/Widget'
import { HelperText } from 'components/primitives/Text/Text.component'

/* Customer feedback in numbers */
const CoworkerMainKPIs = () => {
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()

  const lastCoachingSession = useLastCompletedCoachingSession(coworkerId).data
  const activeMissions = useMissions(coworkerId).data?.filter(
    (mission) => mission.status === MissionStatus.Active
  )
  const { computedSurveyValues, isLoading } = useCSATRollingData(coworkerId)
  const evaluations = useRecentQAs(coworkerId).data

  // define if there is less than 10 surveys available and then show inline warning

  if (isLoading) return <LoadingSkeleton height={'25vh'} />

  return (
    <Flex direction={'column'} gap={space100} w={'100%'} h={'100%'}>
      <Widget border>
        <Grid bg={colourGreyNeutral1}>
          <Grid.Col span={12}>
            <Text headingSize="m">
              {t('features.coworker-kpis.customer-surveys-in-numbers.title')}
            </Text>

            <Text bodySize="l">
              {t('features.coworker-kpis.customer-surveys-in-numbers.subtitle')}
            </Text>
          </Grid.Col>

          <Grid.Col span={3}>
            <KPI
              title={t('features.coworker-kpis.customer-surveys-in-numbers.manner')}
              measurement=" %"
              value={computedSurveyValues?.percentageManner}
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <KPI
              title={t('features.coworker-kpis.customer-surveys-in-numbers.knowledge')}
              measurement=" %"
              value={computedSurveyValues?.percentageKnowledge}
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <KPI
              title={t('features.coworker-kpis.customer-surveys-in-numbers.solution')}
              measurement=" %"
              value={computedSurveyValues?.percentageSolution}
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <KPI
              title={t('features.coworker-kpis.customer-surveys-in-numbers.responses')}
              value={computedSurveyValues?.nrTotalAptitude}
            />
          </Grid.Col>
        </Grid>
      </Widget>

      <Widget border>
        <Grid bg={colourGreyNeutral1}>
          <Grid.Col span={12}>
            <Text headingSize="m">{t('features.coworker-kpis.coaching-in-numbers.title')}</Text>

            <Text bodySize="l">{t('features.coworker-kpis.coaching-in-numbers.subtitle')}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <KPI
              title={t('features.coworker-kpis.coaching-in-numbers.days')}
              value={dayjs().diff(dayjs(lastCoachingSession?.completedDate), 'days')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <KPI
              title={t('features.coworker-kpis.coaching-in-numbers.evaluations')}
              value={evaluations?.QAs.length}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <KPI
              title={t('features.coworker-kpis.coaching-in-numbers.active-missions')}
              value={activeMissions?.length}
            />
          </Grid.Col>
        </Grid>
      </Widget>
    </Flex>
  )
}

export const KPI = ({
  title,
  value,
  description,
  measurement = '',
}: {
  title: string
  value?: number | string
  measurement?: string
  description?: string
}) => {
  return (
    <Widget
      border
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text
          headingSize="s"
          style={{
            width: '100%',
          }}
        >
          {title}
        </Text>
      </Flex>

      <Text
        headingSize="m"
        style={{
          marginTop: space100,
          backgroundColor: colourGreyNeutral2,
          padding: space25,
        }}
      >
        {value ? value + measurement : 'N/A'}
      </Text>

      <HelperText
        style={{
          marginTop: space50,
        }}
      >
        {description}
      </HelperText>
    </Widget>
  )
}

export default CoworkerMainKPIs
