import Button from '@ingka/button'
import Text from '@ingka/text'
import { space50, space125, space200, space300 } from '@ingka/variables'
import { Box, Drawer, Flex, Grid } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MissionStatus } from 'lib/types/mission.types'

import { useCoworker, useTeamQuery } from 'state/slices/api'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

import QaScore from 'components/composites/QualityAssessments/QaScore'
import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { useCurrentSessionData } from 'components/features/CoachingSessions/CoachingSessionEditor/ReviewMissions/ReviewMissions.hooks'
import {
  CoachingPointersStep,
  ConnectedMetricStep,
  DurationStep,
  MetricStep,
  TargetStep,
  TitleStep,
} from 'components/features/Missions/MissionDraftEditor/MissionDraftDrawer/Steps'
import QaNotes from 'components/features/QualityAssessments/QaNotes.component'
import { PageHeader } from 'components/layout/PageHeader'
import { Heading, SubHeading } from 'components/primitives/Text'

import MissionQaProgress from '../../MissionQaProgress/MissionQaProgress.component'
import { useCreateMissionDraft, useUpdateMissionDraft } from '../MissionDraftEditor.hooks'
import { MissionDraftDrawerProps } from '../MissionDraftEditor.types'

// TODO: Clean this up
const MissionDraftDrawer: React.FC<MissionDraftDrawerProps> = ({
  coworkerId,
  showCreateMission,
  closeDrawer,
  isEditing,
  mission,
  selectedCheckboxId,
}) => {
  const { t } = useTranslation()
  const { data: coworker } = useCoworker(coworkerId)
  const { data: team } = useTeamQuery(coworker?.teamId ?? skipToken)

  const {
    createForm,
    onSubmit: onSubmitCreateMission,
    handleSubmit: handleSubmitCreateMission,
    isSuccessCreateMission,
    isLoadingCreateMission,
  } = useCreateMissionDraft({
    coworkerId,
    selectedCheckboxId,
  })

  const {
    form: editForm,
    onSubmit: onSubmitEditMission,
    handleSubmit: handleSubmitEditMission,
    isSuccess: isSuccessUpdateMission,
    isLoading: isLoadingUpdateMission,
  } = useUpdateMissionDraft({
    coworkerId,
    mission,
  })

  const { watch } = createForm || editForm
  const { qaId } = watch()

  const [internalOpen, setInternalOpen] = useState(false)

  const { assessments: relevantQas } = useCurrentSessionData(coworkerId)

  useEffect(() => {
    if (showCreateMission) {
      setInternalOpen(true)
    } else {
      setInternalOpen(false)
    }
  }, [showCreateMission])

  useEffect(() => {
    editForm.setValue('qaId', qaId)
  }, [qaId, editForm])

  const onSubmitForm = () => {
    if (isEditing) {
      onSubmitEditMission()
      console.log('Edit submit trigger')
    } else {
      onSubmitCreateMission()
      console.log('Create submit trigger')
    }
  }

  //Todo: When form is submitted successfullly close the drawer
  useEffect(() => {
    if (isSuccessUpdateMission || isSuccessCreateMission) {
      closeDrawer()
    }
  }, [isSuccessUpdateMission, isSuccessCreateMission, closeDrawer])

  const isLoadingFormSubmission = isEditing ? isLoadingUpdateMission : isLoadingCreateMission

  return (
    <Box>
      {/* Todo: Change the page header back nav to go to the specific step in the flow */}
      <Drawer
        title={
          <PageHeader
            title={
              isEditing
                ? t('features.mission.create-mission.edit-mission')
                : t('features.mission.create-mission.header.title')
            }
          />
        }
        opened={internalOpen}
        onClose={() => {
          setInternalOpen(false)
          closeDrawer()
        }}
        withCloseButton={true}
        withinPortal={true}
        size="100%"
        transitionProps={{
          transition: showCreateMission ? 'slide-up' : 'slide-down',
          duration: 600,
          timingFunction: 'ease',
        }}
        zIndex={1000}
        position="bottom"
      >
        {coworker && team && (
          <Box style={{ backgroundColor: backgroundColourDefault }} h="100%" w="100%">
            <form
              onSubmit={
                isEditing
                  ? handleSubmitEditMission(onSubmitForm)
                  : handleSubmitCreateMission(onSubmitForm)
              }
            >
              <Grid px={space300} py={space125}>
                <Grid.Col span={6}>
                  <TitleStep form={isEditing ? editForm : createForm} coworker={coworker} />
                  <DurationStep
                    form={isEditing ? editForm : createForm}
                    coworker={coworker}
                    mission={mission}
                  />
                  <MetricStep
                    form={isEditing ? editForm : createForm}
                    coworker={coworker}
                    team={team}
                  />
                  <TargetStep
                    form={isEditing ? editForm : createForm}
                    coworker={coworker}
                    team={team}
                  />
                  <CoachingPointersStep form={isEditing ? editForm : createForm} />
                  <ConnectedMetricStep
                    form={isEditing ? editForm : createForm}
                    coworker={coworker}
                    isEditing={isEditing}
                    mission={mission}
                  />

                  <Flex justify="space-between" my={space50}>
                    <Button
                      type="secondary"
                      small
                      text={t('common.actions.cancel')}
                      onClick={() => {
                        setInternalOpen(false)
                        closeDrawer()
                      }}
                    />
                    <Button
                      small
                      type="primary"
                      text={
                        isEditing
                          ? t('common.actions.save-changes')
                          : t('common.actions.save-draft')
                      }
                      htmlType="submit"
                      loading={isLoadingFormSubmission}
                      onClick={(e) => {
                        onSubmitForm() //todo: this is a hack to get the form to submit
                        e.preventDefault()
                      }}
                    />
                  </Flex>
                </Grid.Col>

                {/* Placeholder for QA question details column as in the designs */}
                <Grid.Col span={6}>
                  <Widget
                    header={
                      <WidgetHeader title={<SubHeading>{qaId && t(`qa.${qaId}`)}</SubHeading>} />
                    }
                  >
                    {/* Qa stats inside the form */}
                    {isEditing && mission.status === MissionStatus.Active ? (
                      <MissionQaProgress
                        coworkerId={coworkerId}
                        mission={mission}
                        showChanges={true}
                      />
                    ) : (
                      <Flex direction={'row'} gap={space200}>
                        <Box>
                          <Text>{t('features.mission.preview.initial')}</Text>
                          <Heading>
                            <QaScore coworkerId={coworkerId} qaId={qaId} type="previous" />
                          </Heading>
                        </Box>
                        <Box>
                          <Text>{t('features.mission.preview.current')}</Text>
                          <Heading>
                            <QaScore coworkerId={coworkerId} qaId={qaId} type="current" />
                          </Heading>
                        </Box>
                      </Flex>
                    )}
                  </Widget>

                  <QaNotes
                    coworkerId={coworkerId}
                    assessments={relevantQas}
                    qaId={qaId}
                    hideGeneralNotes
                  />
                </Grid.Col>
              </Grid>
            </form>
          </Box>
        )}
      </Drawer>
    </Box>
  )
}
export default MissionDraftDrawer
