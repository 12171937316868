import Button from '@ingka/button'
import Skeleton from '@ingka/skeleton'
import { Flex, Grid, Stack } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'
import { useGetTeamleadByLegacyId } from 'state/slices/api/modules/user'

import { radius50, spacing50, spacing75, spacing100 } from 'styles/tokens/insikt.tokens'
import { colourNeutralGrey100 } from 'styles/tokens/oldSkapa.tokens'

import { EmptyContent } from 'components/composites/Shared/EmptyContent'
import { Widget } from 'components/composites/Shared/Widget'
import ProfilePhoto from 'components/features/CoworkerMVP/ProfileInfo/ProfilePhoto/ProfilePhoto.component'
import { Paragraph, SubHeading, TitleBlue } from 'components/primitives/Text'

const span = { base: 3, xs: 12, md: 6, lg: 3 }

export const UpcomingCoachingSession = () => {
  const coworkerId = useCoworkerId()
  const { t } = useTranslation()

  const { data: coachingSessions = [], isLoading: isLoadingCoachingSessions } =
    useCoachingSessions(coworkerId)

  const upcomingCoachingSession = coachingSessions.find(
    (coachingSession) => coachingSession.status === CoachingSessionStatus.Planned
  )
  const teamleadId = upcomingCoachingSession?.createdBy
  const { data: teamleadUser, isLoading: isLoadingTeamleadData } = useGetTeamleadByLegacyId(
    teamleadId ?? skipToken
  )

  if (!isLoadingCoachingSessions && !upcomingCoachingSession) {
    return (
      <>
        <SectionHeader />
        <EmptyContent translationKey="features.coaching-session.coworker-coaching-sessions.upcoming-session.warnings.no-planned-session" />
      </>
    )
  }

  return (
    <>
      <SectionHeader />
      <Widget border>
        <Grid justify="flex-start" gutter={'sm'}>
          <Grid.Col span={span}>
            {isLoadingTeamleadData && <Skeleton height="100%" width="100%" />}
            {teamleadUser && (
              <Widget
                style={{
                  background: colourNeutralGrey100,
                  padding: `${spacing50}`,
                  height: '100%',
                  borderRadius: radius50,
                }}
              >
                <Flex justify="flex-start" gap={spacing75} mt={spacing100}>
                  <ProfilePhoto teamleadUser={teamleadUser} />
                  <Stack gap={0} justify="center">
                    <Paragraph>
                      {t('features.coaching-session.coworker-coaching-sessions.teamleader.title')}
                    </Paragraph>
                    <SubHeading>{teamleadUser.name}</SubHeading>
                  </Stack>
                </Flex>
              </Widget>
            )}
          </Grid.Col>
        </Grid>
      </Widget>
    </>
  )
}

const SectionHeader = () => {
  const { t } = useTranslation()
  const { coworkerPath } = useSectionPaths()
  const link = `${coworkerPath}/coaching-sessions`
  return (
    <Flex justify="space-between" align="center" wrap="wrap" py={spacing50}>
      <TitleBlue>
        {t('features.coaching-session.coworker-coaching-sessions.upcoming-session.title')}
      </TitleBlue>

      <Link to={link}>
        <Button type="emphasised" small text={t('common.actions.completed-coaching-sessions')} />
      </Link>
    </Flex>
  )
}
