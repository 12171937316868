import { space50 } from '@ingka/variables'
import { Grid } from '@mantine/core'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useCoachingSessions } from 'state/slices/api'

import { CoachingSessionPreviewSkeleton } from 'components/composites/CoachingSessions/CoachingSessionPreview'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CoachingSessionPreviewListProps } from './CoachingSessionPreviewList.types'
import CoachingSessionPreviewTable from './CoachingSessionPreviewTable.component'
import { NoPlannedCoachingSessionPrompt } from './NoPlannedCoachingSessionPrompt'

const SKELETON_COUNT = 4

export const CoachingSessionPreviewList: React.FC<CoachingSessionPreviewListProps> = ({
  coworkerId,
}) => {
  const {
    data: coachingSessions = [],
    isLoading,
    isError,
    isSuccess,
  } = useCoachingSessions(coworkerId)

  if (isLoading) {
    return <LoadingSkeleton />
  }

  const plannedCoachingSessions = coachingSessions.filter(
    (session) => session.status === CoachingSessionStatus.Planned
  )

  const needToPlanCoachingSession = plannedCoachingSessions.length === 0

  return (
    <>
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {needToPlanCoachingSession && <NoPlannedCoachingSessionPrompt coworkerId={coworkerId} />}
          {coachingSessions && coachingSessions.length !== 0 && (
            <CoachingSessionPreviewTable coachingSessions={coachingSessions} />
          )}
        </>
      )}
    </>
  )
}

const LoadingSkeleton = () => {
  return (
    <Grid mt={space50}>
      {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
        <Grid.Col key={index}>
          <CoachingSessionPreviewSkeleton />
        </Grid.Col>
      ))}
    </Grid>
  )
}
