import { useParams } from 'react-router-dom'

import { analyticsHelper } from 'lib/utils/analytics/analytics.helper'
import { CustomElementClickEventKeys } from 'lib/utils/analytics/analytics.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'

import { useDismissCoworkerCustomerComment } from 'state/slices/api'

import { CoworkerCustomerComments } from 'components/features/CoworkerMVP/CoworkerCustomerComments'
import { CustomerCommentsOverview } from 'components/features/CustomerComments'
import { useCustomerCommentsForTeamleads } from 'components/features/CustomerComments/CustomerComments.hooks'

const CustomerCommentsPage: React.FC = () => {
  const pageType = useCurrentPageType()
  const coworkerId = useCoworkerId()
  const { teamleadId } = useParams()

  const { forwardCustomerComment, dismissCustomerComment } = useCustomerCommentsForTeamleads()

  const [coworkerDismissComment] = useDismissCoworkerCustomerComment()

  const handleForwardComment = async (interactionId: string) => {
    await forwardCustomerComment({ coworkerId, interactionId })
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.ForwardCustomerComment,
        description: 'Custom event for tracking when a teamlead forwards a customer comment.',
      },
      {
        teamleadId: teamleadId,
        coworkerId: coworkerId,
        interactionId: interactionId,
      }
    )
  }

  const handleTeamleadDismissComment = async (interactionId: string) => {
    await dismissCustomerComment({ coworkerId, interactionId })
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.DismissCustomerComment,
        description: 'Custom event for tracking when a teamlead dismisses a customer comment.',
      },
      {
        teamleadId: teamleadId,
        coworkerId: coworkerId,
        isCoworker: false,
        interactionId: interactionId,
      }
    )
  }

  const handleCoworkerDismissComment = async (interactionId: string) => {
    await coworkerDismissComment({ coworkerId, interactionId })
    analyticsHelper.createCustomElementClickEvent(
      {
        key: CustomElementClickEventKeys.DismissCustomerComment,
        description: 'Custom event for tracking when a coworker dismisses a customer comment.',
      },
      {
        coworkerId: coworkerId,
        isCoworker: true,
        interactionId: interactionId,
      }
    )
  }

  return (
    <>
      {pageType === 'teamlead' ? (
        <CustomerCommentsOverview
          coworkerId={coworkerId}
          availableActions={['forward', 'dismiss']}
          onForward={handleForwardComment}
          onDismiss={handleTeamleadDismissComment}
        />
      ) : (
        <CoworkerCustomerComments
          coworkerId={coworkerId}
          availableActions={['dismiss']}
          onDismiss={handleCoworkerDismissComment}
        />
      )}
    </>
  )
}

export default CustomerCommentsPage
