import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const generateRandomData = () => {
  const data = []
  for (let i = 0; i <= 30; i++) {
    data.push({
      date: `2024-09-${i.toString().padStart(2, '0')}`,
      repeat: Math.floor(Math.random() * 4) + 8,
    })
  }
  return data
}

const lineData = [...generateRandomData()]

const RepeatContactGraph = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300} data={lineData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line dataKey="repeat" fill={'#489CE3'} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default RepeatContactGraph
