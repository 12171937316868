import { QualityAssessment, RecentQAs } from 'lib/types/qualityAssessment.types'

import { apiSlice } from 'state/slices/api/api.slice'

// TODO: Look into the tags here
export const qualityAssessmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findQualityAssessmentsByCoworkerId: builder.query<QualityAssessment[], string>({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/quality-assessments`,
        method: 'GET',
      }),
      providesTags: ['QualityAssessment'],
    }),
    findRecentQAsForCoworker: builder.query<RecentQAs, string>({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/quality-assessments/recent`,
        method: 'GET',
      }),
      providesTags: (result, error, coworkerId) => [{ type: 'QualityAssessment', id: coworkerId }],
    }),
  }),
})

export const {
  useFindQualityAssessmentsByCoworkerIdQuery: useQualityAssessments,
  useFindRecentQAsForCoworkerQuery: useRecentQAs,
} = qualityAssessmentSlice
