import { skipToken } from '@reduxjs/toolkit/query'
import { orderBy } from 'lodash'

import { useSurveyMetrics } from './surveyMetric.slice'

// Todo: utilize this hook for sorting and filtering of survey metrics etc.
export const useCSATRollingData = (coworkerId: string | undefined) => {
  const {
    data: surveyMetrics,
    error,
    isLoading,
    isFetching,
  } = useSurveyMetrics(coworkerId ?? skipToken)

  const computedSurveyValues = surveyMetrics?.CSATRolling

  return {
    computedSurveyValues,
    error,
    isLoading,
    isFetching,
  }
}

export const useTransactionalCSAT = (coworkerId: string) => {
  const {
    data: surveyMetrics,
    error,
    isLoading,
    isFetching,
  } = useSurveyMetrics(coworkerId ?? skipToken)

  const transactionalSurveyValues = orderBy(surveyMetrics?.CSATTransactions, 'date_', 'desc')

  return {
    transactionalSurveyValues,
    error,
    isLoading,
    isFetching,
  }
}
